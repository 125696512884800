var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "photo-book-cus-box",
    staticStyle: {
      "background-color": "#E8E4E4"
    }
  }, [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "Diy"
    }
  }), _c('div', {
    staticStyle: {
      "width": "100vw",
      "background-color": "antiquewhite",
      "height": "2vw"
    }
  }), _vm._l(_vm.pictureInfoList, function (item, index) {
    return [_c('div', {
      key: index + 100,
      staticStyle: {
        "width": "100vw",
        "position": "relative",
        "background-color": "antiquewhite",
        "padding": "1vw"
      }
    }, [_vm.pictureInfoList.length > 1 ? _c('div', {
      staticStyle: {
        "font-size": "2vw",
        "color": "white",
        "font-weight": "bold",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center",
        "z-index": "99",
        "position": "absolute",
        "top": "-1.2vw",
        "right": "18.3vw",
        "width": "5vw",
        "height": "5vw",
        "border-radius": "50%",
        "background-color": "#D56460"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteItem(index);
        }
      }
    }, [_vm._v("X")]) : _vm._e(), _c('div', {
      class: {
        'selected': _vm.selectedIndex == index
      },
      staticStyle: {
        "vertical-align": "top",
        "background-color": "#CECECE",
        "overflow": "hidden",
        "position": "relative",
        "touch-action": "pan-y!important",
        "margin": "0 auto"
      },
      style: {
        width: _vm.coverageWidth,
        height: _vm.coverageHeight
      },
      on: {
        "click": function ($event) {
          return _vm.selectPicture(index);
        }
      }
    }, [(_vm.pictureInfoList[index] ? _vm.pictureInfoList[index].coverImgs[0].coverImg : false) ? _c('van-image', {
      staticClass: "custom-cover-image-box",
      staticStyle: {
        "z-index": "10"
      },
      style: {
        width: _vm.coverageWidth,
        height: _vm.coverageHeight,
        top: '0px',
        left: '0px',
        position: 'absolute'
      },
      attrs: {
        "src": _vm.pictureInfoList[index].coverImgs[0].coverImg
      }
    }) : _vm._e(), (_vm.pictureInfoList[index].userImgInfo[0].photoInfo.url ? false : true) ? _c('div', {
      staticStyle: {
        "position": "absolute",
        "right": "0px",
        "bottom": "0px",
        "width": "19.24vw",
        "height": "19.24vw",
        "background-color": "#BBBBBB",
        "opacity": "0.7",
        "z-index": "11"
      }
    }, [_c('van-uploader', {
      staticStyle: {
        "width": "19.24vw",
        "height": "19.24vw",
        "display": "flex",
        "z-index": "11",
        "justify-content": "center",
        "align-items": "center",
        "font-weight": "bold"
      },
      attrs: {
        "max-size": 26214400,
        "accept": "image/*",
        "after-read": _vm.createAfterReadHandler(index)
      },
      on: {
        "oversize": _vm.onOversize
      }
    }, [_c('van-icon', {
      staticStyle: {
        "margin-left": "5vw"
      },
      attrs: {
        "name": "sort"
      }
    }), _c('br'), _vm._v(" upload ")], 1)], 1) : _vm._e(), (_vm.pictureInfoList[index].userImgInfo[0].photoInfo.url ? true : false) ? _c('div', {
      class: {
        'odd-update': index % 2 === 0,
        'even-update': index % 2 === 1
      },
      staticStyle: {
        "position": "absolute",
        "right": "0px",
        "bottom": "0px",
        "width": "19.24vw",
        "height": "19.24vw",
        "background-color": "#BBBBBB",
        "opacity": "0.7",
        "z-index": "11"
      }
    }, [_c('van-uploader', {
      staticStyle: {
        "width": "19.24vw",
        "height": "19.24vw",
        "display": "flex",
        "z-index": "11",
        "justify-content": "center",
        "align-items": "center",
        "font-weight": "bold"
      },
      attrs: {
        "max-size": 26214400,
        "accept": "image/*",
        "after-read": _vm.createAfterReadHandler(index)
      },
      on: {
        "oversize": _vm.onOversize
      }
    }, [_c('van-icon', {
      staticStyle: {
        "margin-left": "5vw"
      },
      attrs: {
        "name": "sort"
      }
    }), _c('br'), _vm._v(" change ")], 1)], 1) : _vm._e(), _c('div', {
      style: {
        width: _vm.cusAreaW,
        height: _vm.cusAreaH,
        top: _vm.cusAreaY,
        left: _vm.cusAreaX,
        position: 'absolute'
      },
      attrs: {
        "id": 'custom-user-image-option-box-' + index + '-area'
      }
    }, [(_vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : false) ? _c('van-image', {
      style: {
        top: _vm.pictureInfoList[index].userImgInfo[0].top,
        left: _vm.pictureInfoList[index].userImgInfo[0].left
      },
      attrs: {
        "src": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : '0',
        "width": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.width : '0',
        "height": _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.height : '0',
        "display": "inline-block"
      },
      on: {
        "load": function ($event) {
          return _vm.onImageLoad(index);
        }
      },
      scopedSlots: _vm._u([{
        key: "loading",
        fn: function () {
          return [_c('van-loading', {
            staticStyle: {
              "color": "red"
            },
            attrs: {
              "type": "spinner",
              "size": "50"
            }
          })];
        },
        proxy: true
      }], null, true)
    }) : _vm._e(), (_vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.url : false) ? _c('div', {
      directives: [{
        name: "touch",
        rawName: "v-touch:pan",
        value: _vm.doAction,
        expression: "doAction",
        arg: "pan"
      }, {
        name: "touch",
        rawName: "v-touch:pinch",
        value: _vm.doAction,
        expression: "doAction",
        arg: "pinch"
      }],
      staticClass: "custom-user-image-option-box",
      class: 'custom-user-image-option-box-' + index,
      style: {
        width: _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.width : '0px',
        height: _vm.pictureInfoList[index] ? _vm.pictureInfoList[index].userImgInfo[0].photoInfo.height : '0px',
        top: _vm.pictureInfoList[index].userImgInfo[0].top,
        left: _vm.pictureInfoList[index].userImgInfo[0].left
      },
      attrs: {
        "id": 'custom-user-image-option-box-' + index
      }
    }) : _vm._e()], 1)], 1), _c('div', {
      staticStyle: {
        "margin-top": "2vw",
        "margin-bottom": "2vw",
        "display": "flex",
        "align-items": "center",
        "justify-content": "center"
      }
    }, [_c('span', {
      staticStyle: {
        "margin-right": "2vw"
      }
    }, [_vm._v("Count:")]), _c('van-button', {
      staticStyle: {
        "border-radius": "50%"
      },
      attrs: {
        "size": "mini",
        "disabled": _vm.pictureInfoList[index].count <= 1,
        "icon": "minus",
        "type": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.addCount(index, -1);
        }
      }
    }), _c('span', {
      staticStyle: {
        "margin-right": "2vw"
      }
    }), _vm._v(" " + _vm._s(_vm.pictureInfoList[index].count) + " "), _c('span', {
      staticStyle: {
        "margin-left": "2vw"
      }
    }), _c('van-button', {
      staticStyle: {
        "border-radius": "50%"
      },
      attrs: {
        "disabled": _vm.upSellLimitMaxCount,
        "size": "mini",
        "icon": "plus",
        "type": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.addCount(index, 1);
        }
      }
    })], 1)])];
  }), _c('div', {
    staticStyle: {
      "background-color": "white",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('van-button', {
    staticStyle: {
      "font-size": "5vw",
      "font-weight": "bold",
      "background-color": "#D56460",
      "color": "white"
    },
    attrs: {
      "disabled": _vm.addDisableFlag
    },
    on: {
      "click": _vm.addPictureCustom
    }
  }, [_vm._v(_vm._s(_vm.addModelText))])], 1), _c('br'), _c('br'), _c('br'), [_vm.canPlaceOrderFlag2 ? _c('van-button', {
    staticClass: "page-bottom-submit-box",
    staticStyle: {
      "z-index": "12",
      "background-color": "#D56460",
      "width": "100vw",
      "height": "12.52vw",
      "font-size": "5vw",
      "font-weight": "bold"
    },
    attrs: {
      "disabled": _vm.canPlaceOrderFlag,
      "type": "primary",
      "size": "large",
      "color": "#D56460"
    },
    on: {
      "click": function ($event) {
        return _vm.postToShoppingCart();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]) : _vm._e(), !_vm.canPlaceOrderFlag2 ? _c('van-uploader', {
    staticClass: "page-bottom-submit-box",
    staticStyle: {
      "z-index": "12",
      "background-color": "#D56460",
      "width": "100vw",
      "height": "12.52vw"
    },
    attrs: {
      "preview-image": false,
      "max-size": 26214400,
      "accept": "image/*",
      "after-read": _vm.afterRead
    },
    on: {
      "oversize": _vm.onOversize
    },
    model: {
      value: _vm.fileList,
      callback: function ($$v) {
        _vm.fileList = $$v;
      },
      expression: "fileList"
    }
  }, [_c('van-button', {
    staticStyle: {
      "font-size": "5vw",
      "font-weight": "bold",
      "width": "100vw",
      "height": "12.52vw",
      "background-color": "#D56460",
      "border-color": "#D56460"
    },
    attrs: {
      "icon": "plus",
      "type": "primary",
      "size": "large"
    }
  }, [_vm._v("Upload Your Picture")])], 1) : _vm._e()], _vm.addAddressFlag ? _c('add-address-popup', {
    attrs: {
      "add-address-popup-show": _vm.addAddressFlag
    },
    on: {
      "callbackForAdd": _vm.callbackForAdd
    }
  }) : _vm._e()], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };